<template>
  <Html :lang="head.htmlAttrs?.lang" :dir="head.htmlAttrs?.dir">
    <Head>
      <template v-for="link in head.link" :key="link.id || link.key">
        <Link
          :id="link.id || link.key"
          :rel="link.rel"
          :href="link.href"
          :hreflang="link.hreflang"
        />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head>
    <Body>
      <Cookies />
      <SkipLinks />
      <div
        ref="pageWrapper"
        class="page-wrapper"
        :style="`--page-height: ${height}px;`"
      >
        <NuxtLazyHydrate when-idle>
          <ClientOnly>
            <PixelsBackground v-if="!pixelAnimationDisabled" />
          </ClientOnly>
        </NuxtLazyHydrate>
        <NuxtLazyHydrate when-idle>
          <NavigationHeader />
        </NuxtLazyHydrate>
        <main id="main-content" class="main-content">
          <slot />
        </main>
        <NuxtLazyHydrate when-visible>
          <NavigationFooter />
        </NuxtLazyHydrate>
        <ClientOnly>
          <TheCursor />
        </ClientOnly>
      </div>
    </Body>
  </Html>
</template>

<script lang="ts" setup>
import { useElementSize } from '@vueuse/core'

import { useIsMobile } from '@/composables/useIsMobile'
import { useAnimationDisabled } from '@/state/pixelAnimationData'

const pixelAnimationDisabled = useAnimationDisabled()

useIsMobile()

const { t } = useI18n()
useHead({
  meta: [
    {
      key: 'description',
      name: 'description',
      content: t('head-meta.description')
    },
    {
      key: 'keywords',
      name: 'keywords',
      content: t('head-meta.keywords')
    },
    {
      key: 'og:description',
      property: 'og:description',
      content: t('head-meta.og:description')
    }
  ]
})

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'key',
  addSeoAttributes: true
})

const pageWrapper = ref<HTMLElement>()
const { height } = useElementSize(pageWrapper)
provide('pageHeight', height)
</script>

<style lang="scss">
.main-content {
  flex: 1;
  transition: opacity $transition;

  .route-change & {
    opacity: 0;
  }

  body:not(.route-change) & {
    opacity: 1;
  }
}
</style>

<style lang="scss" scoped>
.main-content {
  position: relative;
  overflow: clip;
}
</style>
